<template>

  <div class="edit-term m-0 px-3"
       :class="{'vx-card main-box top-zero-radius': !termId, 'no-box-shadow h-100': termId}">
    <div class="edit-term-fields">
      <custom-validate-input :label="$t('terms.labels.name')"
                             class="mb-2"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             v-model="term.name"/>


      <div class="time-picker">
        <div class="date-picker-item">
          <custom-select :label="$t('terms.labels.startTime')"
                         :classes="[{'w-full': true}]"
                         :options="generateTimeRange"
                         :default="term.startTime"
                         @input="term.endTime = {value: '', label: '-'}"
                         v-model="term.startTime" />
        </div>

        <div class="date-picker-item">
          <custom-select :label="$t('terms.labels.endTime')"
                         :classes="[{'w-full': true}]"
                         :options="generateEndTimeOptions"
                         :default="term.endTime"
                         :disabled="term.startTime.label === '-'"
                         v-model="term.endTime" />
        </div>
      </div>
      <vs-button id="deleteUserBTN"
                 class="w-full mt-3"
                 color="danger"
                 v-if="checkUserPermissions('term.delete')"
                 @click="$refs.deleteConfirmation.showDialog()">
        {{ $t('terms.labels.delete') }}
      </vs-button>

      <custom-dialog ref="deleteConfirmation"
                     :title="$t('terms.confirmations.delete.title')"
                     :body="$t('terms.confirmations.delete.body', {name: term.name.value})"
                     @accept="deleteTerm"/>

      <vs-button v-show="false"
                 id="EditUserBTN"
                 @click="sendData"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput.vue'
  import CustomDialog from '../../../../../components/customDialog/customDialog.vue'
  import CustomSelect from '../../../../../components/customSelect/customSelect.vue'
  import {deleteTerm, editTerm, getTerm} from '../../../../../http/requests/club/terms'
  import {checkUserPermissions} from '../../../../../assets/js/functions'
  import {getClubRequirements} from "@/http/requests/requirements";
  import moment from "moment/moment";

  export default {
    name: 'editTerm',
    components: {
      DatePicker,
      CustomSelect,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      termId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        club: {
          timeLength: '30',
          startTime: '00:00',
          endTime: '23:00'
        },
        term: {
          name: {
            value: '',
            isValid: true
          },
          startTime: {
            value: '',
            label: '-',
            isValid: true
          },
          endTime: {
            value: '',
            label: '-',
            isValid: true
          }
        },
        roles: [],
        actions: {
          toolbar: [
            {
              id: 'EditUserBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success',
              permission: 'term.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'editTerm' ? 'term' : 'terms'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    computed: {
      generateTimeRange () {
        // Take a starting point
        const start = moment(this.club.startTime, 'HH:mm');

        // Take a end point
        const end = moment(this.club.endTime, 'HH:mm');
        const timeSeries = [{
          label: this.club.startTime || '00:00',
          value: this.club.startTime || '00:00'
        }];

        while (start.isSameOrBefore(end)) {
          // add time length minutes to the starting point
          const time = start.add(this.club.timeLength, 'm').format('HH:mm')
          timeSeries.push({label: time, value: time});
        }
        return timeSeries
      },
      generateEndTimeOptions () {
        const startTimeIndex = this.generateTimeRange.map(elm => elm.value).indexOf(this.term.startTime.value)
        return this.generateTimeRange.slice(startTimeIndex + 1)
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })
      this.$store.dispatch('setPageTitle', this.$t('terms.edit.title'))
      this.getTerm()
      this.getClubRequirements()
    },
    methods: {
      getTerm() {
        getTerm(this.termId || this.$route.params.id).then(response => {
          const court = response.data.data

          this.term.name.value = court.name || ''
          // this.term.image.value = term.avatar || ''
          this.term.startTime = {
            value: court.start_time || '',
            label: court.start_time || '-'
          }
          this.term.endTime ={
            value: court.end_time || '',
            label: court.end_time || ''
          }

        }).catch(() => {
        })
      },
      getClubRequirements() {
        getClubRequirements().then((response) => {
          const club = response.data.data
          this.club.timeLength = club.time_length || '30'
          this.club.startTime = club.club_start_time || '00:00'
          this.club.endTime = club.club_end_time === '24:00' ? '00:00' : club.club_end_time || '23:00'
        })
      },
      checkUserPermissions,
      deleteTerm() {
        deleteTerm(this.termId || this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('terms.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.$emit('edit')
          this.$router.push({name: 'terms'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('terms.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      },
      sendData() {
        if (!this.term.name.isValid ||
          this.term.startTime.value === '' ||
          this.term.endTime.value === '') {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('terms.notifications.wrongValues'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        if (this.term.startTime.value === this.term.endTime.value) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('terms.notifications.startTimeIsEqualEndTime'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const term = {
          name: this.term.name.value,
          start_time: this.term.startTime.value,
          end_time: this.term.endTime.value
        }

        if (this.term.role && this.term.role.value > 0 && this.termId !== this.$store.state.auth.term.id) term.role_id = this.term.role.value

        editTerm(this.termId || this.$route.params.id, term).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('terms.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('edit')
          this.$router.push({name: 'terms'})

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': this.$t('terms.notifications.parseError.name'),
              'start_time': this.$t('terms.notifications.parseError.startTime'),
              'end_time': this.$t('terms.notifications.parseError.endTime')
            }

            switch (error.response.status) {
              case 422:
                const errors = error.response.data.errors
                let notifyDelay = 0
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  if (errors[error_key].hasOwnProperty('error_key')) {
                    switch (errors[error_key].error_key) {
                      case 'start_time_equal_to_end_time':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.startTimeIsEqualEndTime'),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 2400,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'start_time_conflict':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.startTimeConflict', {name: errors[error_key].term_name}),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'end_time_conflict':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.endTimeConflict', {name: errors[error_key].term_name}),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'term_range_not_match_with_times':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.termRange'),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break
                    }
                  } else {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    },
  }
</script>

<style lang="scss">
.edit-term {
  background-color: #fff;

  .edit-term-fields {
    width: 100%;
    max-width: 400px;

    .custom-profile-image-input {
      label {
        height: 110px;
        width: 110px;
      }
    }

    .useral-custom-element-select {
      .items {
        max-height: 150px;
      }
    }

    .time-picker {
      display: flex;
      justify-content: space-between;

      .date-picker-item {
        border: 0 !important;
        border-radius: 0.4rem;
        position: relative;
        flex-grow: 0.5;
        max-width: 48%;

        .custom-input {
          margin: 0;
        }

        .date-picker-label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 5px;
          padding: 0 5px;
          background-color: #ffffff;
          z-index: 1;
        }

        .vpd-input-group {
          width: 20px !important;
          position: absolute;
          top: 6px;
          left: 2px;

          .vpd-icon-btn {
            opacity: 1 !important;
            height: 25px !important;
            width: 20px !important;
            background-color: inherit !important;

            svg {
              fill: #000000;
              overflow: visible !important;
            }
          }

          .form-control {
            border: 0;
            width: 100%;
            display: none;
            line-height: 35px;
          }
        }
      }
    }

    .input-field-item {
      position: relative;
      border: 1px solid #cdcdcd;
      border-radius: 0.4rem;
      padding: 5px 10px;
      margin: 15px 0;
      display: flex;
      justify-content: space-around;
      min-height: 35px;

      &.invalid {
        border-color: #b00000;

        .input-field-label {
          color: #b00000;
        }
      }

      .input-field-label {
        position: absolute;
        font-size: 12px;
        top: -10px;
        left: 8px;
        background-color: #ffffff;
        padding: 0 5px;
      }

      .radio-item {
        display: flex;
        align-items: center;
        direction: rtl;

        label {
          margin-left: 5px;
        }
      }
    }

    div.edit-term-field {
      position: relative;

      span.label {
        font-size: 12px;
        position: absolute;
        top: -10px;
        left: 7px;
        z-index: 10000;
        background: #ffffff;
        padding: 0 5px;
      }

      .useral-custom-element-select {
        margin: 15px 0;

        .selected {
          height: 35px;
          line-height: 35px;
          font-size: 13px;
        }

        .items {
          line-height: 35px;

          div {
            height: 35px;
            font-size: 13px;
          }
        }
      }
    }

    .error-alert {
      display: inline-block;
      border: 1px solid #ffba00;
      border-radius: .5rem;
      color: #ffba00;
      padding: 0 10px;
      line-height: 50px;
      height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;

      i {
        font-size: 15px;
      }
    }

    &::-webkit-scrollbar {
      display: block;
      border-radius: .2rem;
      width: 10px;
      background: #f9f9fd;
      right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: .2rem;
      background: #cecece;
    }
  }
}
</style>
