import axios from '@/http/axios'

export function getTreasuryRequirements() {
    return axios({
        url: 'v1/admin/treasury/requirements',
        method: 'get'
    })
}

export function getTimeRequirements() {
    return axios({
        url: 'v1/admin/time/requirements',
        method: 'get'
    })
}

export function getClubRequirements() {
    return axios({
        url: 'v1/admin/club/requirements',
        method: 'get'
    })
}