<template>
  <div class="terms-price-list" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'termPriceList'}]">

    <draggable-dynamic-table ref="termsPriceListTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             v-model="data"/>

    <!-- edit term price prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editTermPricePromptStatus"
      @close="editTermPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editTermPriceBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('terms.price.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editTermPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-term-price :term-id="termId" :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="editTimePrice" v-show="false" @click="editTermPricePromptStatus = true"/>
  </div>
</template>

<script>
import axios from "axios";
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import CustomIcon from '../../../../../../components/customIcon/customIcon.vue'
import EditTermPrice from "../edit/editTermsPrice.vue";
import {getTimePrices} from '@/http/requests/club/timePrices'
import { addComma } from "../../../../../../assets/js/functions";

export default {
  name: 'TermsPriceList',
  metaInfo () {
    return {
      title: this.$t('terms.price.list.title')
    }
  },
  components: {
    EditTermPrice,
    CustomIcon,
    DraggableDynamicTable
  },
  props: {
    termId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      editTermPricePromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'termsPriceListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'day_7',
          i18n: 'terms.price.table.header.friday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_6',
          i18n: 'terms.price.table.header.thursday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_5',
          i18n: 'terms.price.table.header.wednesday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_4',
          i18n: 'terms.price.table.header.tuesday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_3',
          i18n: 'terms.price.table.header.monday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_2',
          i18n: 'terms.price.table.header.sunday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_1',
          i18n: 'terms.price.table.header.saturday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'courtName',
          i18n: 'terms.price.table.header.courtName',
          width: '150px',
          minWidth: 120,
          align: 'center',
        }
      ],
      data: [],
      filters: {term: `${this.termId || this.$route.params.id}`},
      sorts: ['order[0]=court_id,asc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'editTimePrice',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning',
              permission: 'term.update'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'termPriceList' ? 'term' : 'terms'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {

    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })
    this.$store.dispatch('setPageTitle', this.$t('terms.price.list.title'))

    this.getTermPrices()
  },
  methods: {
    getTermPrices () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.termsPriceListTable && this.data.length === 0) {
            this.$refs.termsPriceListTable.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.termsPriceListTable && this.data.length > 0) {
            this.$refs.termsPriceListTable.loadMoreStatus = 'Loading'
          }

          getTimePrices(this.page, this.filters, this.sorts).then((res) => {
            this.endedList = res.data.data.length === 0
            let data = {}
            res.data.data.forEach((term) => {
              if (term.court) {
                if (!data[`court_${term.court.id}`]) {
                  data[`court_${term.court.id}`] = {}
                }
                data[`court_${term.court.id}`]['courtName'] = term.court.name || ''
                data[`court_${term.court.id}`][`day_${term.week_day}`] = addComma(term.price)
              }
            })
            const dataArray = Object.values(data)
            setTimeout(() => {
              dataArray.forEach((item) => {
                this.data.push(item)
              })
            }, 100)

            // this.page = res.data.pagination.current_page + 1
            // if (res.data.pagination.current_page === 1) {
            //   const row_index = this.columnsLabel.map((e) => {
            //     return e.field
            //   }).indexOf('row')
            //   this.columnsLabel[row_index].footer.value = res.data.pagination.total
            // }

            if (this.$refs.termsPriceListTable) this.$refs.termsPriceListTable.loadMoreStatus = ''
          })
              .catch((error) => {
                if (this.$refs.termsPriceListTable && !axios.isCancel(error)) this.$refs.termsPriceListTable.loadMoreStatus = 'Danger'
              })
        }
      }, 400)
    },
    reloadCourtsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getTermPrices()
      this.editTermPricePromptStatus = false
    },
    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .terms-price-list {
    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
