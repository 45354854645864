import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
    getTimePrices: createCancelTokenHandler('getTimePrices')
}


export function getTimePrices (page, filters = {}, sorts = []) {

  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

    return axios({
        url: `v1/admin/club/time-prices${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTimePrices'].handleRequestCancellation().token
    })
}



export function editTimePrices (payment) {
    return axios({
        url: 'v1/admin/club/time-prices',
        data: payment,
        method: 'patch'
    })
}
